<template>
  <div class="home">
    <div class="menu sticky">
      <div v-if="copied" class="menu-toaster">
        {{ toaster }}
      </div>
      <div class="menu-logo">
        <a href="#"
          ><img src="./assets/arkana-no-icon.png" class="menu-logo-img"
        /></a>
      </div>
      <div class="menu-items">
        <div class="menu-item">
          <a href="#" class="menu-link"> Home </a>
        </div>
        <div class="menu-item">
          <a href="#services-tag" class="menu-link"> Services </a>
        </div>
        <div class="menu-item">
          <a href="#aboutus-tag" class="menu-link"> About Us </a>
        </div>
        <div class="menu-item">
          <a href="#contact-tag" class="menu-link"> Contact </a>
        </div>
      </div>
      <div class="menu-socials">
        <a
          href="https://www.linkedin.com/company/arkana-cybersec/"
          target="_blank"
          ><img src="./assets/linkedin-logo.png" class="menu-social"
        /></a>
        <img src="./assets/twitter-logo.png" class="menu-social" />
      </div>
    </div>
    
  </div>
  <Header />
  <MainContent />
</template>

<script>
import Header from "./components/Header.vue";
import MainContent from "./components/MainContent.vue";

export default {
  name: "App",
  components: {
    Header,
    MainContent,
  },
  data() {
    return {
      copied: false
    }
  },
  computed: {
    toaster() {
      
      console.log(this.$store.getters['user/getToaster'])
      return this.$store.getters['user/getToaster']
    }
  
  },
  watch: {
    toaster: function() {
      const vm = this;
      if (vm.toaster != null) {
        vm.copied = true
        return
      } else if (vm.toaster === null) {
        vm.copied = false
        return
      }
    }
  }
};
</script>
