<template>
   <div class="header">
    
    <div class="header-top">
      <img src="../assets/logo2.png" class="header-logo">
      
      <div class="header-text">
        Pentesting validation services
      </div>
       

      <div class="header-text2">
        <VueWriter :typeSpeed="70" :iterations='1' :caret="underscore2" :array="['the only thing worse than no security is a false sense of security']">
        <span class="green-accent">></span>
        </VueWriter>
        
      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: 'HeaderElement',
  props: {
    msg: String
  },
  data() {
    return { arr: ["the only thing worse than no security is a false sense of security"]}
  }
}
</script>
