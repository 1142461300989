<template>
  <div class="main-content">
    <div class="section-separator">
      <div class="boxes-container">
        <div class="box">
          <div class="box-header">
            <div class="box-header-left">Arkana</div>
            <div class="box-header-right"></div>
          </div>
          <kinesis-container>
            <kinesis-element :strength="7">
              <div class="box-title">
                Sentinels <span class="red-accent">.</span>
              </div>
            </kinesis-element>
          </kinesis-container>
          <div class="box-text">
            Our special 'sentinels' are deployed into the clients'
            infrastructure so we can clearly see if they are triggered by those
            who are performing the penetration test
          </div>
          <!-- <a href="#services-tag" class="lm"><div class="learn-more">
          Learn More &rarr;
        </div></a> -->
        </div>
        <div class="box">
          <div class="box-header">
            <div class="box-header-left">Arkana</div>
            <div class="box-header-right"></div>
          </div>
          <kinesis-container>
            <kinesis-element :strength="7">
              <div class="box-title">
                ClientSec <span class="red-accent">.</span>
              </div>
            </kinesis-element>
          </kinesis-container>
          <div class="box-text">
            By doing validation checks on cyber security companies that perform
            penetration tests, we position ourselves directly on the client's
            side, completely unbiased
          </div>
          <!-- <a href="#services-tag" class="lm"><div class="learn-more">
          Learn More &rarr;
        </div></a> -->
        </div>
        <div class="box">
          <div class="box-header">
            <div class="box-header-left">Arkana</div>
            <div class="box-header-right"></div>
          </div>
          <kinesis-container>
            <kinesis-element :strength="7">
              <div class="box-title">
                Test The Tester <span class="red-accent">.</span>
              </div>
            </kinesis-element>
          </kinesis-container>
          <div class="box-text">
            Our mission is to make sure the people you hired for a penetration
            test are actually looking for vulnerabilities and not doing a
            superficial job, leaving you unknowingly at risk
          </div>
          <!-- <a href="#services-tag" class="lm"><div class="learn-more">
          Learn More &rarr;
        </div></a> -->
        </div>
      </div>
    </div>
    <div id="services-tag"></div>

    <div class="section-1">
      <div class="section-title">SERVICES</div>
      <div class="line-separator"></div>
        <div class="row-s1">
          <div class="s1-left">
            <img src="../assets/pentest2.jpg" class="s1-img">
          </div>
          <div class="s1-right">
            <div class="section-text-serv">
              A penetration test is meant to assess the effectiveness of the
              implemented cyber defence measures. It is usually carried out by one or
              more skilled ethical hackers who will leave no stone unturned trying to
              discover and exploit cracks in your defences, making you aware of what
              the weaknesses are and how to fix them.

              <br /><br />The problem is, often times they're not that skilled,
              they're not that thorough, or they simply have a bad day. Inadequate penetration testing not only squanders client resources but also fails to uncover real vulnerabilities, creating a potential risk for future breaches. Although certain environments may be resistant to breaches, evaluating the quality of a penetration test remains challenging.

              
            </div>
          </div>
        </div>
        <div class="section-text-separator">

        <span class="blue-accent"
          >How can anyone tell whether the testers did a good job or not?</span
        >

        <br>We're here to help answer this question.
        </div>
      <div class="services-row">
        
        <div class="services-right2">
          
          <div class="services-right-title">
            <img src="../assets/sentinel.png" class="sentinel-icon">
            <p>Sentinels</p>
            

          </div>
            <div class="services-right-text">
              Our team and collaborators have developed <span class="green-accent2">the most advanced and
              effective </span>way for you to confirm that the penetration tests being
              performed on your systems are indeed thoroughly executed and are not
              a waste of money and time.

              <br /><br />The Sentinels are deployed into the clients' infrastructure, where they serve as a
              'decoy' for those who are performing the penetration test. They are personalized to mimic the network being tested and can be easily set up by our team or the clients' IT department.

              <br /><br />A well performed test should trigger all our Sentinels,
              or at least as many as possible.
            </div>
            
          </div>
        
        </div>
        
      </div>
      
      <div class="services-items-row">
        <div class="services-item">
          <div class="item-icon">
            &#10003;
          </div>
          <div class="item-text">
            Completely non-invasive
          </div>
        </div>
        <div class="services-item">
          <div class="item-icon">
            &#10003;
          </div>
          <div class="item-text">
            No access required to your network
          </div>
        </div>
        <div class="services-item">
          <div class="item-icon">
            &#10003;
          </div>
          <div class="item-text">
            Easy to set up
          </div>
        </div>
        <div class="services-item">
          <div class="item-icon">
            &#10003;
          </div>
          <div class="item-text">
            Customizable for your company
          </div>
        </div>
        <div class="services-item">
          <div class="item-icon">
            &#10003;
          </div>
          <div class="item-text">
            Multiple complexity decoys
          </div>
        </div>
      </div>



    <div id="aboutus-tag"></div>
    <div class="section-1">
      <div class="section-title">ABOUT US</div>
      <div class="line-separator"></div>
      <div class="section-text">
        The Cyber Security Engineers that developed our Sentinels and came up
        with the entire Pentest Validation system work for the top Cybersec
        companies in the world.

        <br /><br />They know first-hand how often times pentests are executed
        superficially and, more importantly, they know how to verify if the test
        was properly done or not.
      </div>
      <div class="services-disclaimer">
        <br /><br />Given the nature of our services, we are forced
        <span class="green-accent2">to protect the identities </span>of our
        Engineers, which could face serious backlash from their own employers
        and the entire Cybersec community.
      </div>
      <div class="about-us-row">
        <div class="about-us-box">
          <div class="about-us-image">
            <img src="../assets/header-img-ish.png" class="about-img" />
          </div>
          <div class="about-us-name">I. Arata</div>
          <div class="about-us-title">CEO</div>
          <div class="about-us-socials">
            <img src="../assets/email.png" class="twitter-logo" @click="copyToClipboard('arata@arkana-cybersec.com')" />
            <a
              href="https://www.linkedin.com/in/ishiki-arata-509305157/"
              target="_blank"
              ><img src="../assets/linkedin-logo.png" class="socials-logo"
            /></a>
            <img src="../assets/twitter-logo.png" class="twitter-logo" />
            
          </div>
        </div>
        <div class="about-us-box">
          <div class="about-us-image">
            <img src="../assets/header-img-mih.png" class="about-img" />
          </div>
          <div class="about-us-name">M. Badea</div>
          <div class="about-us-title">CSO</div>
          <div class="about-us-socials">
            <img src="../assets/email.png" class="twitter-logo" />
            <a
              href="https://www.linkedin.com/in/mihai-badea-64a3b1143/"
              target="_blank"
              ><img src="../assets/linkedin-logo.png" class="socials-logo"
            /></a>
           
          </div>
        </div>
        <div class="about-us-box">
          <div class="about-us-image">
            <img src="../assets/header-img-cse.png" class="about-img" />
          </div>
          <div class="about-us-name">G. R.</div>
          <div class="about-us-title">CTO</div>
          <div class="about-us-socials">
            <img src="../assets/email.png" class="twitter-logo" />
          </div>
        </div>
        <div class="about-us-box">
          <div class="about-us-image">
            <img src="../assets/header-img-cse.png" class="about-img" />
          </div>
          <div class="about-us-name">D. G.</div>
          <div class="about-us-title">Software Engineer</div>
          <div class="about-us-socials">
            <img src="../assets/email.png" class="twitter-logo" />
          </div>
        </div>
      </div>
    </div>
    <div id="contact-tag"></div>
    <div class="section-1">
      <div class="section-title">CONTACT</div>
      <div class="line-separator"></div>
      <div class="section-text">
        Get in touch with us through the contact form below
      </div>
      <div class="contact-row">

      
      <div class="contact-form-box">
        <form action="" class="contact-form">
          <div class="form-group">
            <label class="form-label">Name <span class="red-accent">*</span></label>
            <input type="text" class="form-input" v-model="form.name" placeholder="Required" required />
          </div>
          <div class="form-group">
            <label class="form-label">Email <span class="red-accent">*</span></label>
            <input type="text" class="form-input" v-model="form.email" placeholder="Required" required />
          </div>
          <div class="form-group">
            <label class="form-label">Telephone</label>
            <input type="text" class="form-input" v-model="form.telephone" placeholder="Optional" />
          </div>
          <div class="form-group">
            <label class="form-label">Company</label>
            <input type="text" class="form-input" v-model="form.company" placeholder="Optional" />
          </div>
          <div class="form-group">
            <label class="form-label">Message <span class="red-accent">*</span></label>
            <textarea
              name="message"
              id=""
              cols="30"
              rows="10"
              placeholder="Required"
              v-model="form.message"
              required
              class="contact-textarea"
            ></textarea>
          </div>
          <button class="contact-btn" @click.prevent="onSubmit()">Send</button>
        </form>
      </div>
      <div class="contact-box">
        <div class="contact-img">
          <img src="../assets/office.jpg" class="office-img">
        </div>
        <div class="section-text-contact">
          Contact information
        </div>
        <div class="contact-mail">
          <img src="../assets/phone-logo.png" class="contact-logo" />
          <div class="contact-text copy-mail">+40 757 765 956</div>
          
        </div>
        <div class="contact-mail">
          <img src="../assets/email.png" class="contact-logo" />
          <div class="contact-text copy-mail" @click="copyToClipboard('office@arkana-cybersec.com')">office@arkana-cybersec.com</div>
          <img src="../assets/paste.png" @click="copyToClipboard('office@arkana-cybersec.com')" class="paste-logo" />
        </div>
        <div class="contact-mail">
          <a
            href="https://linkedin.com/company/arkana-cybersec/"
            target="_blank"
            class="lm"
          >
            <img src="../assets/linkedin-logo.png" class="contact-logo"
          /></a>
          <div class="contact-text">
          <a
            href="https://linkedin.com/company/arkana-cybersec/"
            target="_blank"
            class="lm"
            >Linkedin</a
          ></div>
        </div>
        
        <div class="contact-mail">
          <a
            href="https://linkedin.com/company/arkana-cybersec/"
            target="_blank"
            class="lm"
            ><img src="../assets/twitter-logo.png" class="contact-logo"
          /></a>
          <div class="contact-text">
            <a
              href="https://linkedin.com/company/arkana-cybersec/"
              target="_blank"
              class="lm"
              >Twitter</a
            >
          </div>
        </div>
      </div>
    </div>
    </div>

    <div class="footer">
      <div class="footer-container">
        <div class="footer-top"></div>
        <div class="footer-bottom">© Copyright 2024 Arkana Cybersec</div>
      </div>
    </div>
   
  </div>
  <div v-if="scY > 500" class="scroll-top sticky" @click="scrollToTop()">
    <img src="../assets/arrow.png" class="arrow-top">
    </div>
</template>

<script>
import { reactive } from 'vue'
import { useStore } from 'vuex'

export default {
  name: "MainContent",
  props: {
    msg: String,
    copyClipboard: Boolean
  },
  data () {
    return {
      scTimer: 0,
      scY: 0,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll)
  },
  methods: {
    onScroll () {
      if (this.scTimer) return;
        this.scTimer = setTimeout(() => {
          this.scY = window.scrollY;
          clearTimeout(this.scTimer);
          this.scTimer = 0;
        }, 100);
    },
    scrollToTop() {
      window.scrollTo(0,0)
    }
    
  },
  setup() {

    const store = useStore();
    const form = reactive({
      name: '',
        email: '',
        company: '',
        telephone: '',
        message: ''
    })
    function copyToClipboard(text) {
      
      navigator.clipboard.writeText(text)
      store.commit('user/setToaster', 'Copied to clipboard')

    }

    function onSubmit() {

      store.commit('user/setToaster', 'Message sent')
      this.form.name = ''
      this.form.email = ''
      this.form.company = ''
      this.form.telephone = ''
      this.form.message = ''
    }

    return {
      copyToClipboard,
      onSubmit,
      form
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
