


const user = {
    namespaced: true,
    state: {
        toaster: null

      

    },
    getters: {
        getToaster(state) {
            return state.toaster
        },

        
    },
    mutations: {
        setToaster (state, payload) {
            state.toaster = payload
            console.log(this.toaster)
            setTimeout(() => {
                state.toaster = null
            }, 3000)
            
        },




        
    },
    actions: {
       setToaster({commit}, payload) {
        
        console.log('test')
        commit('setToaster', payload)
       }
        
    }
}
    


export default user